import { useStore } from 'vuex';
import axios from 'axios';

export default defineNuxtRouteMiddleware(async ({ query: { id } }) => {
  if (import.meta.client) return;

  const store = useStore();

  try {
    const { data } = await axios.get(`${useRuntimeConfig().CMS_PREVIEW_URL}/${id}`);
    store.commit('setPageConfig', data);
  } catch (e) {
    console.error(e);
    return abortNavigation({
      statusCode: 404,
      message: 'Page not found',
    });
  }
});
